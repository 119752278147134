import React, { useEffect, useState } from "react";

// import popup from "../assests/popup.png"
import moment from "moment";
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaBasketShopping,
  FaCalendar,
  FaCheck,
  FaCross,
  FaUntappd,
  FaX,
} from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import { useGetBudgetsQuery } from "../../../redux/apis/budget";
import { CURRENCY, format_date, getMonths } from "../../../utils/helper";
import { useGetOrdersQuery } from "../../../redux/apis/order";
import { FaCrosshairs, FaDollarSign } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

const Budget = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.userSlice);
  const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] =
    useTableControls();

  const [from, setFrom] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().endOf("month").format("YYYY-MM-DD"));

  const [month, setMonth] = useState(moment().month() + 1);

  const { data, isLoading: budgetLoading } = useGetBudgetsQuery({
    page,
    rowsPerPage,
    salesman: user?._id,
    from,
    to,
  });

  const {
    data: orders,
    isLoading,
    isFetching,
  } = useGetOrdersQuery({
    page,
    rowsPerPage,
    createdBy: user?._id,
    search,
    from,
    to,
  });

  console.log("Orderssss", orders);

  const [totalOrderValue, setTotalOrderValue] = useState(0);
  useEffect(() => {
    setTotalOrderValue(0);
    const sumOrderValues = budget?.orders?.reduce(
      (total, product) => total + product.orderValue,
      0
    );
    setTotalOrderValue(sumOrderValues);
  }, [orders?.data]);


  if (data && data?.data) {
    var budget = data?.data?.[0];
  }
  console.log(budget, "budget");
  const onPageChange = (page) => setPage(page);

  const onMonthChange = (m) => {
    setMonth(m);

    let year = new Date().getFullYear();

    let f = moment(`${year}-${m}`, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    let e = moment(`${year}-${m}`, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");

    setFrom(f);
    setTo(e);
  };

  return (
    <div>
      {/* <Header/>
            <SideBar/> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-sm-8 col-3 main-heading-1">
                  <h2 style={{ padding: "36px 9px 20px" }}>Assigned Budget</h2>
                </div>
                <div className="col-4  align-items-center justify-content-center">
                  <Dropdown>
                    <Dropdown.Toggle className="text-capitalize m-0">
                      {getMonths()[month - 1]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      {getMonths()?.map((itemm, index) => (
                        <Dropdown.Item
                          className="text-capitalize"
                          value={index + 1}
                          onClick={() => onMonthChange(index + 1)}
                        >
                          {itemm}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className="col-sm-8 col-9 m-b-20">
                                    <input type="search" name className="search-1 search-2 aprov-sarc" placeholder="Search Here...." value={search} onChange={e => setSearch(e.target.value)} />
                                    <input type="date" name className="search-1 mx-1" placeholder="Search Here...." value={from} onChange={e => setFrom(e.target.value)} max={to} />
                                    <input type="date" name className="search-1 mx-1" placeholder="Search Here...." value={to} onChange={e => setTo(e.target.value)} min={from} />
                                </div> */}
              </div>
              <>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaCrosshairs />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.amount} {CURRENCY}
                            </h3>
                            <span className="widget-title2">Target Amount</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaArrowDown />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.remainingConfirmedTarget} {CURRENCY}
                            </h3>
                            <span className="widget-title2">
                              Remaining Confirmed Target
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaBasketShopping />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.remaingUnConfimedTarget} {CURRENCY}
                            </h3>
                            <span className="widget-title2">
                              Remaining Unconfirmed Target
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaDollarSign />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.totalAmountOfOrders} {CURRENCY}{" "}
                              <span style={{ fontSize: "12px" }}>
                                |{" "}
                                {(
                                  (Number(budget?.totalAmountOfOrders) /
                                    Number(budget?.amount)) *
                                  100
                                ).toFixed(2)}{" "}
                                %
                              </span>
                            </h3>
                            <span className="widget-title2">
                              Total Amount of Orders
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaCheck />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.totalAmountOfPaidOrders} {CURRENCY}{" "}
                              <span style={{ fontSize: "12px" }}>
                                |{" "}
                                {(
                                  (Number(budget?.totalAmountOfPaidOrders) /
                                    Number(budget?.amount)) *
                                  100
                                ).toFixed(2)}{" "}
                                %
                              </span>
                            </h3>
                            <span className="widget-title2">
                              Paid Order Amount
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaX />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.totalAmountOfUnpaidOrder} {CURRENCY}{" "}
                              <span style={{ fontSize: "12px" }}>
                                |{" "}
                                {(
                                  (Number(budget?.totalAmountOfUnpaidOrder) /
                                    Number(budget?.amount)) *
                                  100
                                ).toFixed(2)}{" "}
                                %
                              </span>
                            </h3>
                            <span className="widget-title2">
                              Unpaid Order Amount
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaCheck />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.totalAmountOfCreditedOrder} {CURRENCY}{" "}
                              <span style={{ fontSize: "12px" }}>
                                |{" "}
                                {(
                                  (Number(budget?.totalAmountOfCreditedOrder) /
                                    Number(budget?.amount)) *
                                  100
                                ).toFixed(2)}{" "}
                                %
                              </span>
                            </h3>
                            <span className="widget-title2">
                              Credited Order Amount
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaX />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {budget?.totalAmountOfOverdueOrder} {CURRENCY}{" "}
                              <span style={{ fontSize: "12px" }}>
                                |{" "}
                                {(
                                  (Number(budget?.totalAmountOfOverdueOrder) /
                                    Number(budget?.amount)) *
                                  100
                                ).toFixed(2)}{" "}
                                %
                              </span>
                            </h3>
                            <span className="widget-title2">
                              Overdue Order Amount
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaDollarSign />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          {/* <div className="dash-widget-info">
                            <h3>
                              {budget?.totalAmountOfOrders} {CURRENCY}{" "}
                              <span style={{ fontSize: "12px" }}>
                                |{" "}
                               {totalOrderValue}
                              </span>
                            </h3>
                            <span className="widget-title2">Total Assigned Budget</span>
                          </div> */}
                          <div className="dash-widget-info">
                          <span>
                         {totalOrderValue == budget?.totalAmountOfCreditedOrder ? "Yes":"No"}
                            {/* {totalOrderValue} {CURRENCY}{" "}
                            <span style={{ fontSize: "12px" }}>
                                | {totalOrderValue}
                            </span> */}
                          </span>
                          <span className="widget-title2">
                            Budget Target is achieved ?
                          </span>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <Table
                headings={[
                  "S. No",
                  "Customer Name",
                  "Customer Email",
                  "Customer Type",
                  "Price",
                  "Registration Date",
                  "Status",
                ]}
                data={budget?.orders}
                loading={isFetching}
                length={budget?.orders?.length}
              >
                {budget?.orders?.map((item, index) => (
                  <tr>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.customer?.fullName}</td>
                    <td>{item?.customer?.email || "-"}</td>
                    <td
                      className={`${
                        item?.customer?.newCustomer
                          ? "text-success"
                          : "text-primary"
                      }`}
                    >
                      {item?.customer?.newCustomer ? "A" : "B"} Customer
                    </td>
                    <td>
                      {item?.orderValue} {CURRENCY}
                    </td>
                    <td>{format_date(item?.orderCreatedAt)}</td>
                    <td
                      className={`text-capitalize ${
                        item?.status === "overdue"
                          ? "text-danger"
                          : item?.status === "paid"
                          ? "text-success"
                          : "text-black"
                      }`}
                    >
                      {item?.status}
                    </td>
                    {/* <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className="text-black text-decoration-underline cursor-pointer my-0 mx-1" onClick={() => navigate(`/salesman/orders/edit/${item?._id}`, { state: { order: item } })} >Edit</p>
                                                    <p className="text-primary text-decoration-underline cursor-pointer my-0 mx-1" onClick={() => navigate(`/salesman/orders/detail/${item?._id}`)} >View</p>
                                                </div>
                                            </td> */}
                  </tr>
                ))}
              </Table>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Budget;
