import React, { useState, useEffect } from "react";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { FaConciergeBell } from "react-icons/fa";
import { FaCalendar, FaCoins, FaPerson } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetAdminDashboardQuery,
  useGetUsersQuery,
} from "../redux/apis/user";
import {
  CURRENCY,
  getDaysLabel,
  getMonths,
  getWeeks,
  monthlyIntervalDate,
} from "../utils/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userSlice);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTerm, setSelectedTerm] = useState(searchParams.get("filter"));
  const [selectedSalesman, setSelectedSalesman] = useState();
  const [selectedWeek, setSelectedWeek] = useState(
    getWeeks()?.find(
      (item) => item?.start <= moment().week() && item?.end >= moment().week()
    )
  );

  console.log(selectedTerm, "initialFilter");
  useEffect(() => {
    const initialFilter = searchParams.get("filter") || "daily";
    setSelectedTerm(initialFilter);

    // Update the URL when the page number changes
    // setSearchParams({ filter: initialFilter });
  }, []);

  let currentMonth = new Date().getMonth();
  let dateCalc = monthlyIntervalDate();
  let lastMonthDateCalc = monthlyIntervalDate(currentMonth);
  const {
    data: dashboard,
    isLoading,
    refetch,
    isFetching,
  } = useGetAdminDashboardQuery({
    selectedSalesman: selectedSalesman ? selectedSalesman?._id : null,
    type: selectedTerm,
    role: user?.role,
    currentMonthEndDate: dateCalc?.to,
    currentMonthStartDate: dateCalc?.from,
    previousMonthEndDate: lastMonthDateCalc?.to,
    previousMonthStartDate: lastMonthDateCalc?.from,
  });

  useEffect(() => {
    refetch();
  }, [searchParams, refetch]);

  const { data } = useGetUsersQuery({
    page: 1,
    rowsPerPage: 1000,
    active: "true",
  });

  const getWeeksLabels = (value) => {
    let arr = new Array(value?.end - value?.start + 1)
      .fill("")
      .map((_, index) => "Week " + (index + value?.start));
    return arr;
  };

  // useEffect(() => {
  //   if (data && data?.data) {
  //     setSelectedSalesman(data?.data?.find(item => item?._id === user?._id))
  //   }
  // }, [data])

  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedDatasetIndex = elements[0].datasetIndex;
      const indexx = elements[0].index + 1;
      const clickedStack = stacks[clickedDatasetIndex];

      let currentTime = new Date();
      let fromDate = null,
        toDate = null;
      if (selectedTerm === "monthly") {
        fromDate = moment(indexx + "-" + currentTime.getFullYear(), "MM-YYYY")
          .startOf("month")
          .format("YYYY-MM-DD");
        toDate = moment(indexx + "-" + currentTime.getFullYear(), "MM-YYYY")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (selectedTerm === "weekly") {
        let weeks = [];

        for (let i = selectedWeek?.start; i <= selectedWeek?.end; i++) {
          weeks.push(i);
        }

        let start = moment().week(weeks[indexx - 1]);

        fromDate = moment(start).startOf("week").format("YYYY-MM-DD");
        toDate = moment(start).endOf("week").format("YYYY-MM-DD");
      } else if (selectedTerm === "daily") {
        let start = moment().startOf("week").add(1, "day");
        let dates = [];
        for (let i = 0; i < 5; i++) {
          dates.push(moment(start).add(i, "day").format("YYYY-MM-DD"));
        }
        fromDate = dates[indexx - 1];
        toDate = dates[indexx - 1];
      }

      let selected = {};

      if (selectedSalesman) {
        selected = {
          createdBy: selectedSalesman?._id,
        };
      }

      navigate(`/${user?.role}/dashboard/orders?page=1`, {
        state: { data: { ...clickedStack, fromDate, toDate, ...selected } },
      });
    }
  };

  const stacks = [
    {
      newCustomer: "true",
      status: "paid",
    },
    {
      newCustomer: "true",
      status: "unpaid",
    },
    {
      newCustomer: "true",
      status: "credited",
    },
    {
      newCustomer: "true",
      status: "overdue",
    },
    {
      newCustomer: "false",
      status: "paid",
    },
    {
      newCustomer: "false",
      status: "unpaid",
    },
    {
      newCustomer: "false",
      status: "credited",
    },
    {
      newCustomer: "false",
      status: "overdue",
    },
  ];

  // Define a function to calculate the stack sums
  const footer = (tooltipItems) => {
    let totalSum = 0;
  console.log(tooltipItems,"tooltipItemstooltipItems")
    tooltipItems.forEach((tooltipItem) => {
      const { parsed } = tooltipItem;
      console.log(tooltipItem ,"tooltipItem")
      const value = parsed.y;
  
      if (value > 0) {
        totalSum += value;
      }
    });
  
    return `\nTotal Sum of ${tooltipItems[0].label}: ${totalSum}`;
  };

  // Define a function to display label and value in tooltip
  const label = (tooltipItem) => {
    const datasetLabel = tooltipItem.dataset.label || "";
  console.log("datasetLabeldatasetLabel",datasetLabel)
    const value = tooltipItem.raw;
    return value > 0? `${datasetLabel}: ${value}` : '';
  return "";
  };

  return (
    <div>
      {/* <Header/> */}
      {/* <SideBar/> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="row align-items-center">
              <div className="col-10">
                <div className="main-heading-1">
                  <h2>Dashboard</h2>
                </div>
              </div>
            </div>
            {isFetching ? (
              <div className="d-flex w-100 align-items-center justify-content-center h-50">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaCoins />
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dash-widget-info">
                            <h3>
                              {dashboard?.data?.totalEarnings || "0"} {CURRENCY}
                            </h3>
                            <span className="widget-title2">
                              Total Earnings
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6 text-right">
                      <div className="dash-widget-info">
                        <span className="widget-title2 sinc">100% <br />Since last week </span>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaConciergeBell />
                      </span>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dash-widget-info">
                            <h3>{dashboard?.data?.totalOrders || "0"}</h3>
                            <span className="widget-title2">Total Orders </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6 text-right">
                      <div className="dash-widget-info">
                        <span className="widget-title2 sinc">100% <br />Since last week </span>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaPerson />
                      </span>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dash-widget-info">
                            <h3>{dashboard?.data?.customersCount || "0"}</h3>
                            <span className="widget-title2">
                              Total Customers{" "}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6 text-right">
                      <div className="dash-widget-info">
                        <span className="widget-title2 sinc">100% <br />Since last week </span>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                    <div className="dash-widget">
                      <span className="dash-widget-bg2">
                        <FaCalendar />
                      </span>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dash-widget-info">
                            <h3>{moment().format("DD-MM-YYYY") || "0"}</h3>
                            <span className="widget-title2">Date</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {user?.role === "salesman" ? (
                    <>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                        <div className="dash-widget">
                          <span className="dash-widget-bg2">
                            <FaCoins />
                          </span>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="dash-widget-info">
                                <h3>
                                  {dashboard?.data?.previousSalary?.salary ||
                                    "0"}{" "}
                                  {CURRENCY}
                                </h3>
                                <span className="widget-title2">
                                  Previous Month Salary
                                </span>
                              </div>
                            </div>
                            {/* <div className="col-md-6 text-right">
                      <div className="dash-widget-info">
                        <span className="widget-title2 sinc">100% <br />Since last week </span>
                      </div>
                    </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                        <div className="dash-widget">
                          <span className="dash-widget-bg2">
                            <FaCoins />
                          </span>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="dash-widget-info">
                                <h3>
                                  {dashboard?.data?.currentSalary?.salary ||
                                    "0"}{" "}
                                  {CURRENCY}
                                </h3>
                                <span className="widget-title2">
                                  Current Month Salary
                                </span>
                              </div>
                            </div>
                            {/* <div className="col-md-6 text-right">
                      <div className="dash-widget-info">
                        <span className="widget-title2 sinc">100% <br />Since last week </span>
                      </div>
                    </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                        <div className="dash-widget">
                          <span className="dash-widget-bg2">
                            <FaPerson />
                          </span>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="dash-widget-info">
                                <h3>{dashboard?.data?.present || "0"}</h3>
                                <span className="widget-title2">
                                  Present Salesman
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                        <div className="dash-widget">
                          <span className="dash-widget-bg2">
                            <FaPerson />
                          </span>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="dash-widget-info">
                                <h3>{dashboard?.data?.totalAbsent || "0"}</h3>
                                <span className="widget-title2">
                                  Absent Salesman
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  {/* {chartData && <Line className="w-100" data={chartData.data} options={chartData.options} />} */}

                  <div className="row my-3">
                    <div className="col-4">
                      <Pie
                        data={{
                          labels: ["A Customers", "B Customers"],
                          datasets: [
                            {
                              data: [
                                dashboard?.data?.newCustomers,
                                dashboard?.data?.existingCustomers,
                              ],
                              backgroundColor: [
                                "rgb(93, 173, 226)",
                                "rgb(72, 201, 176)",
                              ],
                              hoverBackgroundColor: [
                                "rgba(54, 162, 235, 0.8)",
                                "rgba(75, 192, 192, 0.8)",
                              ],
                            },
                          ],
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <Doughnut
                        data={{
                          labels: ["Paid", "Unpaid", "Credited", "Overdue"],
                          datasets: [
                            {
                              label: "Count",
                              data: [
                                dashboard?.data?.paid,
                                dashboard?.data?.unpaid,
                                dashboard?.data?.credited,
                                dashboard?.data?.overdue,
                              ],
                              backgroundColor: [
                                "rgb(72, 201, 176)",
                                "rgb(236, 112, 99)",
                                "rgb(54, 162, 235)",
                                "rgb(123, 36, 28)",
                              ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-between">
                    <div className="col-4">
                      {user?.role === "admin" && (
                        <Dropdown>
                          <Dropdown.Toggle className="text-capitalize m-0 w-100">
                            {selectedSalesman
                              ? selectedSalesman?.fullName
                              : "All"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            <Dropdown.Item
                              className="text-capitalize"
                              onClick={() => setSelectedSalesman()}
                            >
                              All
                            </Dropdown.Item>
                            {data?.data?.map((item) => (
                              <Dropdown.Item
                                className="text-capitalize"
                                onClick={() => setSelectedSalesman(item)}
                              >
                                {item?.fullName}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    <div className="col-4">
                      <Dropdown>
                        <Dropdown.Toggle className="text-capitalize m-0 w-100">
                          {selectedTerm}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                          {/* <Dropdown.Item className="text-capitalize" onClick={() => setSelectedTerm("daily")} >Daily</Dropdown.Item> */}
                          <Dropdown.Item
                            className="text-capitalize"
                            onClick={() => {
                              setSearchParams({ filter: "daily" });
                              setSelectedTerm("daily");
                            }}
                          >
                            Daily
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-capitalize"
                            onClick={() => {
                              setSearchParams({ filter: "weekly" });
                              setSelectedTerm("weekly");
                            }}
                          >
                            Weekly
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-capitalize"
                            onClick={() => {
                              setSearchParams({ filter: "monthly" });
                              setSelectedTerm("monthly");
                            }}
                          >
                            Monthly
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {selectedTerm === "weekly" && (
                      <div className="col-4">
                        <Dropdown>
                          <Dropdown.Toggle className="text-capitalize m-0 w-100">
                            {
                              getWeeks()?.find(
                                (item) =>
                                  item.start === selectedWeek?.start &&
                                  item.end === selectedWeek?.end
                              )?.label
                            }
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            {getWeeks()?.map((item) => (
                              <Dropdown.Item
                                className="text-capitalize"
                                onClick={() =>
                                  setSelectedWeek({
                                    start: item?.start,
                                    end: item?.end,
                                  })
                                }
                              >
                                {item?.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                  <div className="row my-3">
                    <div className="col-12 mt-4">
                      <Bar
                        options={{
                          onClick: handleBarClick,
                          interaction: {
                            intersect: false,
                            mode: "index",
                          },
                          plugins: {
                            tooltip: {
                              callbacks: {
                                footer: footer,
                                label: label,
                              },
                            },
                          },
                          responsive: true,
                          scales: {
                            x: {
                              stacked: true,
                            },
                            y: {
                              stacked: true,
                            },
                          },
                        }}
                        data={{
                          labels:
                            selectedTerm === "monthly"
                              ? getMonths()
                              : selectedTerm === "weekly"
                              ? getWeeksLabels(selectedWeek)
                              : getDaysLabel(),
                          datasets: [
                            {
                              label: "A Customers - Paid",
                              backgroundColor: "rgba(63,90,54, 1)",
                              borderColor: "rgba(63,90,54, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.newCustomer?.paidOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.newCustomer?.paidOrders
                                      ),
                              stack: "stack1", // Specify the stack option for this dataset
                            },
                            {
                              label: "A Customers - Unpaid",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                              borderColor: "rgba(63,90,54, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.newCustomer?.unpaidOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.newCustomer
                                            ?.unpaidOrders
                                      ),
                              // data: [5, 10, 15, 20, 25],
                              stack: "stack1", // Specify the same stack option for this dataset
                            },
                            {
                              label: "A Customers - Credited",
                              backgroundColor: "rgba(186,0,28, 1)",
                              borderColor: "rgba(63,90,54, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.newCustomer
                                          ?.creditedOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.newCustomer
                                            ?.creditedOrders
                                      ),
                              // data: [5, 10, 15, 20, 25],
                              stack: "stack1", // Specify the same stack option for this dataset
                            },
                            {
                              label: "A Customers - Overdue",
                              backgroundColor: "rgba(255,255,0, 1)",
                              borderColor: "rgba(63,90,54, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.newCustomer
                                          ?.overDueOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.newCustomer
                                            ?.overDueOrders
                                      ),
                              // data: [5, 10, 15, 20, 25],
                              stack: "stack1", // Specify the same stack option for this dataset
                            },
                            {
                              label: "B Customers - Paid",
                              backgroundColor: "rgba(0,48,143, 1)",
                              borderColor: "rgba(0,48,143, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.existingCustomers
                                          ?.paidOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.existingCustomers
                                            ?.paidOrders
                                      ),
                              stack: "stack2", // Specify a different stack option for this dataset
                            },
                            {
                              label: "B Customers - Unpaid",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                              borderColor: "rgba(0,48,143, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.existingCustomers
                                          ?.unpaidOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.existingCustomers
                                            ?.unpaidOrders
                                      ),
                              // data: [5, 10, 15, 20, 25],
                              stack: "stack2", // Specify the same stack option for this dataset
                            },
                            {
                              label: "B Customers - Credited",
                              backgroundColor: "rgba(186,0,28, 1)",
                              borderColor: "rgba(0,48,143, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.existingCustomers
                                          ?.creditedOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.existingCustomers
                                            ?.creditedOrders
                                      ),
                              stack: "stack2", // Specify the same stack option for this dataset
                            },
                            {
                              label: "B Customers - Overdue",
                              backgroundColor: "rgba(255,255,0, 1)",
                              borderColor: "rgba(0,48,143, 1)",
                              borderWidth: 1,
                              data:
                                selectedTerm === "monthly" ||
                                selectedTerm === "daily"
                                  ? dashboard?.data?.stats?.map(
                                      (item) =>
                                        item?.data[0]?.existingCustomers
                                          ?.overDueOrders
                                    )
                                  : dashboard?.data?.stats
                                      ?.filter(
                                        (item) =>
                                          item?.week >= selectedWeek?.start &&
                                          item?.week <= selectedWeek?.end
                                      )
                                      ?.map(
                                        (item) =>
                                          item?.data[0]?.existingCustomers
                                            ?.overDueOrders
                                      ),
                              stack: "stack2", // Specify the same stack option for this dataset
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
