import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Table from "../../../components/Table";
import { useGetSalaryQuery } from "../../../redux/apis/order";
import { CURRENCY, amountFormatter, format_date, getLastTwoYears, getMonthYear, getMonths, monthlyIntervalDate } from "../../../utils/helper";

let currentYear = new Date().getFullYear()
let payrollDate = 20

let options = [
    {
        label: "Jan - Feb",
        from: new Date(`${currentYear}-01-${payrollDate}`),
        to: new Date(`${currentYear}-02-${payrollDate}`),
    },
    {
        label: "Feb - Mar",
        from: new Date(`${currentYear}-02-${payrollDate}`),
        to: new Date(`${currentYear}-03-${payrollDate}`),
    },
    {
        label: "Mar - Apr",
        from: new Date(`${currentYear}-03-${payrollDate}`),
        to: new Date(`${currentYear}-04-${payrollDate}`),
    },
    {
        label: "Apr - May",
        from: new Date(`${currentYear}-04-${payrollDate}`),
        to: new Date(`${currentYear}-05-${payrollDate}`),
    },
    {
        label: "May - June",
        from: new Date(`${currentYear}-05-${payrollDate}`),
        to: new Date(`${currentYear}-06-${payrollDate}`),
    },
    {
        label: "June - July",
        from: new Date(`${currentYear}-06-${payrollDate}`),
        to: new Date(`${currentYear}-07-${payrollDate}`),
    },
    {
        label: "July - Aug",
        from: new Date(`${currentYear}-07-${payrollDate}`),
        to: new Date(`${currentYear}-08-${payrollDate}`),
    },
    {
        label: "Aug - Sep",
        from: new Date(`${currentYear}-08-${payrollDate}`),
        to: new Date(`${currentYear}-09-${payrollDate}`),
    },
    {
        label: "Sep - Oct",
        from: new Date(`${currentYear}-09-${payrollDate}`),
        to: new Date(`${currentYear}-10-${payrollDate}`),
    },
    {
        label: "Oct - Nov",
        from: new Date(`${currentYear}-10-${payrollDate}`),
        to: new Date(`${currentYear}-11-${payrollDate}`),
    },
    {
        label: "Nov - Dec",
        from: new Date(`${currentYear}-11-${payrollDate}`),
        to: new Date(`${currentYear}-12-${payrollDate}`),
    },
]


const SalaryDetails = () => {

    const d = getMonthYear()

    const { user } = useSelector(state => state.userSlice)

    const { id } = useParams()

    const [month, setMonth] = useState(d.month)
    const [year, setYear] = useState(d.year)

    let [dateData, setDateData] = useState(monthlyIntervalDate())

    const { data, isLoading, isFetching } = useGetSalaryQuery({ id, from: dateData?.from, to: dateData?.to })

    if (data && data?.data) {
        var salaryData = data?.data
    }

    useEffect(() => {
        if (month || year) {
            let data = monthlyIntervalDate(month, year)
            setDateData(data)
        }
    }, [month, year])

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="headd1">
                            <h2 style={{ color: '#000', padding: '36px 9px 20px', fontWeight: 700, lineHeight: '40px', fontSize: '30px' }}> <Link to={`/${user?.role}/salary`} className="btn back"><FaArrowLeft /></Link>Salary Details</h2>
                            {/* <img src={rectangle} /> */}

                        </div>
                        <div className="row mb-4">
                            <div className="col-4" />
                            <div className="col-4" />
                            <div className="col-2">
                                <Dropdown onChange={e => console.log("dsaads", e.target.value)}>
                                    <Dropdown.Toggle className="text-capitalize m-0">
                                        {month > 0 ? getMonths()[month - 1] : getMonths()[11]}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="w-100">
                                        {
                                            getMonths()?.map((item, index) => (
                                                <Dropdown.Item className="text-capitalize" onClick={() => setMonth(index + 1)} >{item}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-2">
                                <Dropdown onChange={e => console.log("dsaads", e.target.value)}>
                                    <Dropdown.Toggle className="text-capitalize m-0">
                                        {year}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="w-100">
                                        {
                                            getLastTwoYears()?.map((item, index) => (
                                                <Dropdown.Item className="text-capitalize" onClick={() => setYear(item)} >{item}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {
                            isLoading ? <Spinner /> :
                                <div className="col-md-12">
                                    <div className="texxtwrp">
                                        <div className="d-flex flex-row row">
                                            <div className="col-4">
                                                <h4>Salesman Name</h4>
                                                <p>{salaryData?.salesman?.fullName}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Salesman Email</h4>
                                                <p>{salaryData?.salesman?.email}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Monthly Salary</h4>
                                                <p>{amountFormatter(salaryData?.totalAmountEarned?.toFixed(2))} {CURRENCY}</p>
                                            </div>
                                        </div>
                                        <h3>Monthly Orders</h3>
                                        <Table
                                            headings={['S. No', 'Customer Name', "Product", "Price", "Percent", "Amount Earned", "Order Date", "Order Paid On", "Status"]}
                                            data={data}
                                            loading={isFetching}
                                            length={salaryData?.orders?.length}
                                        >
                                            {
                                                salaryData?.orders?.map((item, index) => (
                                                    <tr>
                                                        <td>{(index + 1)}</td>
                                                        <td>{item?.customer?.fullName}</td>
                                                        <td>{item?.productName == "google-adds" ? "Google Ads" : "SEO"}</td>
                                                        <td>{item?.orderValue} {CURRENCY}</td>
                                                        <td>{item?.commission || "0 %"}</td>
                                                        <td>{amountFormatter((item?.amountEarned)?.toFixed(2))} {CURRENCY}</td>
                                                        <td>{format_date(item?.orderCreatedAt)}</td>
                                                        <td>{format_date(item?.orderPaidOn)}</td>
                                                        <td className="text-capitalize text-success">{item?.status}</td>
                                                        {/* <td onClick={() => navigate(`/admin/orders/detail/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">View</td> */}
                                                    </tr>
                                                ))
                                            }
                                        </Table>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SalaryDetails;