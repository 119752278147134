import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import { useGetBudgetByIdQuery } from "../../../redux/apis/budget";
import { useChangeLeaveStatusMutation } from "../../../redux/apis/leaves";
import { CURRENCY, amountFormatter, format_date, getMonths } from "../../../utils/helper";

const BudgetDetails = () => {

    const { user } = useSelector(state => state.userSlice)
    const { settings } = useSelector(State => State.generalSlice)

    const leavesStatus = settings?.leavesStatus?.filter(item => item !== "notResponded")?.map(item => ({ label: item, value: item }))

    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading, refetch } = useGetBudgetByIdQuery(id)
    const [updateStatus, { isSuccess }] = useChangeLeaveStatusMutation()

    if (data) {
        var details = data?.data
    }

    const onChangeStatus = (status) => {

        let payload = {
            id,
            status
        }

        updateStatus(payload)

    }

    useEffect(() => {
        if (isSuccess) {
            toast.success("Status Successfully Updated")
            refetch()
        }
    }, [isSuccess])

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="headd1">
                            <h2 style={{ color: '#000', padding: '36px 9px 20px', fontWeight: 700, lineHeight: '40px', fontSize: '30px' }}> <Link to={`/${user?.role}/budget`} className="btn back"><FaArrowLeft /></Link>Budget Details</h2>
                            {/* <img src={rectangle} /> */}
                        </div>
                        {
                            isLoading ? <Spinner /> :
                                <div className="col-md-12">
                                    <div className="texxtwrp">
                                        {/* <div className="row mb-4">
                                            <div className="col-3" />
                                            <div className="col-3" />
                                            <div className="col-3" />
                                            <div className="col-3">
                                                <Dropdown>
                                                    <Dropdown.Toggle className="text-capitalize m-0">
                                                        {leavesStatus?.find(item => item.value === details?.status)?.label}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="w-100">
                                                        {
                                                            getMonths()?.map(itemm => (
                                                                <Dropdown.Item className="text-capitalize" onClick={() => onChangeStatus(itemm)} >{itemm}</Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div> */}
                                        <div className="d-flex flex-row row">
                                            <div className="col-4">
                                                <h4>Salesman Name</h4>
                                                <p>{details?.salesman?.fullName}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Salesman Email</h4>
                                                <p>{details?.salesman?.email}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Salesman Phone Number</h4>
                                                <p>{details?.salesman?.phoneNumber || "-"}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row row">
                                            <div className="col-4">
                                                <h4>From Date</h4>
                                                <p>{format_date(details?.from)}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>To Date</h4>
                                                <p>{format_date(details?.to)}</p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Budget Assigned</h4>
                                                <p className="text-capitalize">{amountFormatter(details?.amount)} {CURRENCY}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-row row">
                                            <div className="col-4">
                                                <h4>Total Amount of Orders</h4>
                                                <p>{amountFormatter(details?.totalAmountOfOrders)} {CURRENCY} <span style={{ fontSize: '12px' }}>| {((Number(details?.totalAmountOfOrders) / Number(details?.amount)) * 100).toFixed(2)} %</span></p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Amount of Paid Orders</h4>
                                                <p>{amountFormatter(details?.totalAmountOfPaidOrders)} {CURRENCY} <span style={{ fontSize: '12px' }}>| {((Number(details?.totalAmountOfPaidOrders) / Number(details?.amount)) * 100).toFixed(2)} %</span></p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Amount of Unpaid Orders</h4>
                                                <p className="text-capitalize">{amountFormatter(details?.totalAmountOfUnpaidOrder)} {CURRENCY} <span style={{ fontSize: '12px' }}>| {((Number(details?.totalAmountOfUnpaidOrder) / Number(details?.amount)) * 100).toFixed(2)} %</span></p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Amount of Credited Orders</h4>
                                                <p>{amountFormatter(details?.totalAmountOfCreditedOrder)} {CURRENCY} <span style={{ fontSize: '12px' }}>| {((Number(details?.totalAmountOfCreditedOrder) / Number(details?.amount)) * 100).toFixed(2)} %</span></p>
                                            </div>
                                            <div className="col-4">
                                                <h4>Amount of Overdue Orders</h4>
                                                <p className="text-capitalize">{amountFormatter(details?.totalAmountOfOverdueOrder)} {CURRENCY} <span style={{ fontSize: '12px' }}>| {((Number(details?.totalAmountOfOverdueOrder) / Number(details?.amount)) * 100).toFixed(2)} %</span></p>
                                            </div>
                                        </div>
                                        <div className="color-bggg">
                                            <div className="row">
                                                <div className="col-sm-6 col-3 main-heading-1">
                                                    <h2>Orders</h2>
                                                </div>
                                            </div>
                                            <Table
                                                headings={['S. No', 'Customer Name', "Price", "Registration Date", "Order Paid Date", "Status"]}
                                                data={details?.orders}
                                                loading={isLoading}
                                                length={details?.orders?.length}
                                            >

                                                {
                                                    details?.orders?.map((item, index) => (
                                                        <tr>
                                                            <td>{(index + 1)}</td>
                                                            <td>{item?.customer?.fullName}</td>
                                                            <td>{amountFormatter(item?.orderValue)} {CURRENCY}</td>
                                                            <td>{format_date(item?.orderCreatedAt)}</td>
                                                            <td>{item?.orderPaidOn ? format_date(item?.orderPaidOn) : "--"}</td>
                                                            <td className={`text-capitalize ${item?.status === "paid" ? "text-success" : item?.status === "overdue" ? "text-danger" : "text-black"}`}>{item?.status}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div></div>
    );
}

export default BudgetDetails;
