import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'general/'

export const GeneralApiService = createApi({
    reducerPath: 'generalApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getSettings: builder.query({
            query: () => "settings",
            keepUnusedDataFor: 0
        }),
        getNotification: builder.query({
            query: () => "notifications",
            keepUnusedDataFor: 0
        }),
        uploadFile: builder.mutation({
            query: (payload) => ({
                url: 'uploadFile',
                method: 'POST',
                body: payload
            }),
        }),
        markAsRead: builder.mutation({
            query: (payload) => ({
                url: `mark-as-read/${payload.id}`,
                method: 'POST'
            }),
        }),
        markAllAsRead: builder.mutation({
            query: () => ({
                url: `mark-all-read`,
                method: 'POST'
            }),
        }),

    }),
})

export const { useGetSettingsQuery, useMarkAllAsReadMutation, useGetNotificationQuery, useLazyGetNotificationQuery, useUploadFileMutation, useMarkAsReadMutation } = GeneralApiService