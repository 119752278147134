import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import {
  useDeleteOrderBinMutation,
  useDeleteOrderMutation,
  useGetOrdersQuery,
  useGetUnactiveOrdersQuery,
  useMarkAsPaidMutation,
} from "../../../redux/apis/order";
import {
  CURRENCY,
  amountFormatter,
  format_date,
  getMonths,
} from "../../../utils/helper";

const BinOrder = () => {
  const navigate = useNavigate();

  const { settings } = useSelector((State) => State.generalSlice);

  console.log("see", settings);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    status,
    setStatus,
  ] = useTableControls();
  const { data, isLoading, refetch } = useGetUnactiveOrdersQuery ({
    page,
    rowsPerPage,
    search,
    from,
    to,
    status,
  });
  const [changeStatus, { isSuccess }] = useMarkAsPaidMutation();
  const [deleteOrderBin, { isSuccess: orderDeletedBin }] = useDeleteOrderBinMutation();


  
  useEffect(() => {
    if (isSuccess) {
      refetch();
      toast.success("Order Status Updated Successfully");
    }
    if (orderDeletedBin) {
      refetch();
      toast.success("Order Updated Successfully");
    }
  }, [isSuccess, orderDeletedBin]);

  const onPageChange = (page) => setPage(page);

  const onChangeStatus = (id, status) => {
    changeStatus({ id, status });
  };

  const onClickDelete = (id) => {
    deleteOrderBin({id, type:"delete"});
  };
  const onClickRecycle = (id) => {
    deleteOrderBin({id, type:"recover"});
  };
  console.log(data?.data, "data?.data?");

  const deletedData = data?.data?.filter((e) => e.isDeleted) || [];
  console.log(deletedData, ":deletedData");
  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              <div className="row">
                <div className="col-sm-3 col-3 main-heading-1">
                  <h2>Recycle Orders</h2>
                </div>
                <div className="col-sm-7 col-5 m-b-20">
                  {/* <a href="add-salesman" className="btn btn btn-primary btn-rounded float-right oder">All Orders</a> */}
                  <input
                    type="search"
                    name
                    className="search-1 mx-1"
                    placeholder="Search Here...."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <input
                    type="date"
                    name
                    className="search-1 mx-1"
                    placeholder="Search Here...."
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    max={to}
                  />
                  <input
                    type="date"
                    name
                    className="search-1 mx-1"
                    placeholder="Search Here...."
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    min={from}
                  />
                </div>
                <div className="col-sm-2 col-5">
                  <Dropdown>
                    <Dropdown.Toggle className="text-capitalize m-0">
                      {status ? status : "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item
                        className="text-capitalize"
                        onClick={(e) => setStatus("")}
                      >
                        {"All"}
                      </Dropdown.Item>
                      {settings?.paymentStatus?.map((item, index) => (
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={(e) => setStatus(item)}
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Table
                headings={[
                  "S. No",
                  "Salesman Name",
                  "Customer Name",
                  "Customer Type",
                  "Price",
                  "Registration Date",
                  "Order Paid Date",
                  "Status",
                  "Action",
                ]}
                data={data}
                loading={isLoading}
                length={data?.data?.length}
              >
                {deletedData?.map((item, index) => (
                  <tr>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.createdBy?.fullName}</td>
                    <td>{item?.customer?.fullName}</td>
                    <td
                      className={`${
                        item?.customer?.newCustomer
                          ? "text-success"
                          : "text-primary"
                      }`}
                    >
                      {item?.customer?.newCustomer ? "A" : "B"} Customer
                    </td>
                    <td>
                      {amountFormatter(item?.orderValue)} {CURRENCY}
                    </td>
                    <td>{format_date(item?.orderCreatedAt)}</td>
                    <td>
                      {item?.orderPaidOn
                        ? format_date(item?.orderPaidOn)
                        : "--"}
                    </td>
                    {/* {
                      <td className="redd">
                        <Dropdown style={{ background: "white" }}>
                          <Dropdown.Toggle
                            className={`m-0 bg-white text-capitalize ${
                              item?.status === "paid"
                                ? "text-success"
                                : item?.status === "overdue"
                                ? "text-danger"
                                : "text-black"
                            }`}
                          >
                            {item?.status.toLowerCase()}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            {settings?.paymentStatus
                              ?.filter((item) => item !== "overdue")
                              .reverse()
                              .map((itemm) => (
                                <Dropdown.Item
                                  className={`text-capitalize ${
                                    item?.status === itemm &&
                                    "bg-primary text-white"
                                  }`}
                                  onClick={() =>
                                    onChangeStatus(item?._id, itemm)
                                  }
                                >
                                  {itemm}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    } */}


{
                      <td className="redd">
                            {item?.status.toLowerCase()}
                         </td>
                    }
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        {/* <p
                          className="text-decoration-underline cursor-pointer my-0 mx-1"
                          onClick={() =>
                            navigate(`/admin/orders/edit/${item?._id}`, {
                              state: { order: item },
                            })
                          }
                        >
                          Edit
                        </p> */}
                        <p
                          className="text-danger text-decoration-underline cursor-pointer my-0 mx-1"
                          onClick={() => onClickDelete(item?._id)}
                        >
                          Delete
                        </p>
                        <p
                          className="text-danger text-decoration-underline cursor-pointer my-0 mx-1"
                          onClick={() => onClickRecycle(item?._id)}
                        >
                          Recover
                        </p>
                        <p
                          className="text-primary text-decoration-underline cursor-pointer my-0 mx-1"
                          onClick={() =>
                            navigate(`/admin/orders/detail/${item?._id}`)
                          }
                        >
                          View
                        </p>
                      </div>
                    </td>
                    {/* <td onClick={() => navigate(`/admin/orders/detail/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">View</td> */}
                    {/* <td className="text-right">
                                                                <div className="dropdown dropdown-action">
                                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><FaEllipsisV /></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a class="dropdown-item" href="edit-patient.html"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_patient"><FaTrashAlt /> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                  </tr>
                ))}
              </Table>
            </div>
            <Pagination
              length={data?.data?.length}
              totalDocs={data?.total}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </div>
          {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};
export default BinOrder;
