import moment from "moment";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaArrowLeft, FaArrowRight, FaCalendar } from "react-icons/fa";
import Table from "../../../components/Table";
import { useGetSalesmanAttendanceQuery } from "../../../redux/apis/attendance";
import {
  DAYS,
  calculate_salary_percent,
  getMonthYear,
  getMonths,
  monthlyIntervalDate,
} from "../../../utils/helper";
import { FRIDAY_WORKING_HOURS, WORKING_HOURS } from "../../../utils/constants";
import { Modal } from "react-bootstrap";

const Attendance = () => {
  const d = getMonthYear();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [month, setMonth] = useState(d.month);
  const [year, setYear] = useState(d.year);
  const [comment, setComment] = useState("");
  let [dataToShow, setDataToShow] = useState([]);
  let [dateData, setDateData] = useState(monthlyIntervalDate());
  let [todaysData, setTodaysData] = useState(null);
  let [thisMonthTotalWorkingPercentage, setThisMonthTotalWorkingPercentage] =
    useState(0.0);

  let showComment = (item) => {
    setComment(item);
    handleShow();
  };

  useEffect(() => {
    if (month) {
      let data = monthlyIntervalDate(month);
      setDateData(data);
    }
  }, [month]);

  const { data, refetch, isLoading, isFetching } =
    useGetSalesmanAttendanceQuery({ from: dateData.from, to: dateData.to });

  useEffect(() => {
    if (data) {
      let thisMonthTotalWorkingHours = 0;
      let noOfBusinessHours = 0;

      let temp = dateData?.monthlyDates?.map((item) => {
        let date = item;
        let day = moment(date, "DD-MM-YYYY").day();

        let isWeekend = day === 0 || day === 6;

        let dataDate = null,
          details = null;

        if (!isWeekend) {
          noOfBusinessHours += day === 5 ? FRIDAY_WORKING_HOURS : WORKING_HOURS;
          dataDate = data?.data?.map((item) => {
            console.log(item, "itemitemitemitem");
            if (item) {
              if (moment(item?.checkInTime).format("DD-MM-YYYY") == date) {
                let thisAttendance = item;

                let checkin = thisAttendance?.checkInTime
                  ? moment(thisAttendance.checkInTime).format("hh:mm a")
                  : "--";
                let checkout = thisAttendance?.checkoutTime
                  ? moment(thisAttendance.checkoutTime).format("hh:mm a")
                  : "--";

                thisMonthTotalWorkingHours += thisAttendance?.duration
                  ? thisAttendance?.duration
                  : 0;

                return {
                  checkInTime: checkin,
                  checkoutTime: checkout,
                  isApproved: item?.isApproved,
                  percentage: thisAttendance?.checkoutTime
                    ? calculate_salary_percent(thisAttendance?.duration, day)
                    : 0.0,

                  isLateApproved: thisAttendance?.isLateApproved,
                  isEarlyApproved: thisAttendance?.isEarlyApproved,
                  isAbsentApproved: thisAttendance?.isAbsentApproved,
                  AbsentApproveComment: thisAttendance?.AbsentApproveComment,
                  EarlyApproveComment: thisAttendance?.EarlyApproveComment,
                  LateApproveComment: thisAttendance?.lateApproveComment,
                };
              }
            }
          });

          details = dataDate.filter((item) => item !== undefined)[0];
        }

        let isAfter = moment(date, "DD-MM-YYYY").isAfter(moment().format());

        return {
          date,
          day,
          attendance: details,
          percentage: `${
            details?.percentage
              ? `${details?.percentage} %`
              : isAfter
              ? "--"
              : "0%"
          }`,
          isApproved: item?.isApproved,
          status: isWeekend
            ? "Weekend"
            : details
            ? "Present"
            : isAfter
            ? "--"
            : "Absent",
          isLateApproved: details?.isLateApproved,
          isEarlyApproved: details?.isEarlyApproved,
          isAbsentApproved: details?.isAbsentApproved,
          AbsentApproveComment: details?.AbsentApproveComment,
          EarlyApproveComment: details?.EarlyApproveComment,
          LateApproveComment: details?.lateApproveComment,
        };
      });

      let workingPercent = (
        (thisMonthTotalWorkingHours / noOfBusinessHours) *
        100
      ).toFixed(2);

      setThisMonthTotalWorkingPercentage(workingPercent);
      setDataToShow(temp);

      let recent = temp
        ?.filter((item) => {
          return item.date == moment().format("DD-MM-YYYY");
        })
        ?.map((item) => ({
          date: item.date,
          attendance: {
            checkInTime: item?.attendance?.checkInTime || "--",
            checkoutTime: item?.attendance?.checkoutTime || "--",
          },
        }));

      setTodaysData(recent[0]);
    }
  }, [data]);
  console.log(dataToShow, "dataToShowdataToShow");
  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              {todaysData && (
                <>
                  <div className="row">
                    <div className="col-6 main-heading-1">
                      <h2>Today's Attendance</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                      <div className="dash-widget">
                        <span className="dash-widget-bg2">
                          <FaCalendar />
                        </span>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dash-widget-info">
                              <h3>{todaysData?.date}</h3>
                              <span className="widget-title2">Date</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                      <div className="dash-widget">
                        <span className="dash-widget-bg2">
                          <FaArrowRight />
                        </span>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dash-widget-info">
                              <h3>{todaysData?.attendance?.checkInTime}</h3>
                              <span className="widget-title2">Time In</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                      <div className="dash-widget">
                        <span className="dash-widget-bg2">
                          <FaArrowLeft />
                        </span>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dash-widget-info">
                              <h3>{todaysData?.attendance?.checkoutTime}</h3>
                              <span className="widget-title2">Time Out</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row mt-2">
                <div className="texxtwrp">
                  <div className="d-flex flex-row row">
                    <div className="col-4">
                      <h4>Monthly Working Percentage</h4>
                      <p>{thisMonthTotalWorkingPercentage} %</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-9 main-heading-1">
                  <h2>Monthly Attendance</h2>
                </div>
                <div className="col-3 d-flex align-items-center mt-1">
                  <Dropdown>
                    <Dropdown.Toggle className="text-capitalize m-0">
                      {month > 0 ? getMonths()[month - 1] : getMonths()[11]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      {getMonths().map((item, index) => (
                        <Dropdown.Item
                          className="text-capitalize"
                          onClick={() => setMonth(index + 1)}
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Table
                headings={[
                  "S. No",
                  "Date",
                  "Day",
                  "Time In",
                  "Time out",
                  "Working %",
                  "Late Approved",
                  "Early Approved",
                  "Absent Approved",
                  // "Comment",
                  "Status",
                ]}
                data={dataToShow}
                loading={isFetching}
                length={dataToShow?.length}
              >
                {dataToShow?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.date}</td>
                    <td>{DAYS[item?.day]}</td>
                    <td>{item?.attendance?.checkInTime || "--"}</td>
                    <td>{item?.attendance?.checkoutTime || "--"}</td>
                    <td>{item?.percentage}</td>
                    <td
                      className={`text-capitalize ${
                        item?.isLateApproved
                          ? "text-success"
                          : item?.isLateApproved === false
                          ? "text-danger"
                          : ""
                      }`}
                      onClick={() => {
                        if (item?.isLateApproved) {
                          showComment(item?.LateApproveComment);
                        }
                      }}
                    >
                      {item?.isLateApproved ? "Yes" : "No"}
                    </td>
                    <td
                      className={`text-capitalize ${
                        item?.isEarlyApproved
                          ? "text-success"
                          : item?.isEarlyApproved === false
                          ? "text-danger"
                          : ""
                      }`}
                      onClick={() => {
                        if (item?.isEarlyApproved) {
                          showComment(item?.isEarlyApproved);
                        }
                      }}
                    >
                      {item?.isEarlyApproved ? "Yes" : "No"}
                    </td>
                    <td
                      className={`text-capitalize`}
                      onClick={() => {
                        if (item?.isAbsentApproved) {
                          showComment(item?.isAbsentApproved);
                        }
                      }}
                    >
                      {item?.isAbsentApproved ? "Yes" : "No"}
                    </td>

                    <td
                      className={`text-capitalize ${
                        item?.status === "Present"
                          ? "text-success"
                          : item?.status === "Absent"
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      {item?.status}
                      {/* status */}
                    </td>
                  </tr>
                ))}
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <label htmlFor="comment">Comment for Approve</label>
            <p>{comment}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Attendance;
