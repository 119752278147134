import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import media from "../assests/Media.svg";
import login from "../assests/login.png";
import { useVerifyOtpMutation } from "../redux/apis/auth";

const Verification = () => {

  const { state } = useLocation()
  const navigate = useNavigate()

  const [values, setValues] = useState({
    one: '',
    two: '',
    three: '',
    four: ''
  })

  const [verify, { isSuccess }] = useVerifyOtpMutation()

  const onClickContinue = e => {
    e.preventDefault()
    verify({
      email: state?.email,
      otp: values.one + values.two + values.three + values.four
    })
  }

  useEffect(() => {
    if (isSuccess) {
      navigate("/set-password", { state: { email: state?.email } })
    }
  }, [isSuccess])

  return (
    <div>
      <section className="does_work_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft bgg-cl">
              <div className="does_work_boxes">
                <div className="form-logo">
                  {/* <img src={media} /> */}
                </div>
                <div className="does_work_box1 ">
                  <h3>Verification Code</h3>
                  <p>Please check your email for verification code. <br />
                    Your code is 4 digit in length.</p>
                  <div className="contact_form_all" >
                    <div className>
                      <div className="col-sm-12">
                        <div className="md-form">
                          <h6 style={{ padding: '20px 0 13px 32px' }}>Verification Code</h6>
                          <div id="wrapper">
                            <div id="dialog">
                              <div id="form-1">
                                <input type="text" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={values?.one} onChange={e => setValues({ ...values, one: e.target.value })} />
                                <input type="text" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={values?.two} onChange={e => setValues({ ...values, two: e.target.value })} /><input type="text" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={values?.three} onChange={e => setValues({ ...values, three: e.target.value })} /><input type="text" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={values?.four} onChange={e => setValues({ ...values, four: e.target.value })} />
                                <div className="resnd">
                                  <a href="Javascript:;" className="resnd-pass">Resend Code</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="page_name" defaultValue="Postcard" />
                    <div className="col-md-12 login">
                      <button className="btn blue_btn" onClick={onClickContinue}>Continue</button>
                    </div>
                    <div className="col-sm-12">
                      <div className="col-md-12">
                        <div className="uuull">
                          <ul>
                            <li>Back to</li>
                            <li><a href="log-in" className="login-2">Login</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="frst-form-img">
                <img src={login} />
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div >

  );
}

export default Verification;