import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft, FaEdit, FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useUploadFileMutation } from "../../../redux/apis/general";
import {
  useAddInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
} from "../../../redux/apis/order";
import { CURRENCY, format_date, isPdf } from "../../../utils/helper";
import {
  useCreateCommentMutation,
  useGetCommentsQuery,
} from "../../../redux/apis/comments";
import moment from "moment";
import { toast } from "react-toastify";

const pdfImage = require("../../../assests/pdf.png");

const OrderDetails = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userSlice);

  const { id } = useParams();

  const invoiceRef = useRef(null);
  const [invoice, setInvoice] = useState();

  const [published, setPublished] = useState(false);
  const [comment, setComment] = useState("");

  const [upload, { data: fileData, isSuccess }] = useUploadFileMutation();
  const [deleteInvoice, { isSuccess: isDeleted }] = useDeleteInvoiceMutation();
  const [addInvoice, { data: invoiceData, isSuccess: invoiceSuccess }] =
    useAddInvoiceMutation();
  const [addComment, { data: commentData, isSuccess: commentSuccess }] =
    useCreateCommentMutation();
  const [isEditingCommission, setIsEditingCommission] = useState(false);
  const [updateOrder, { data: orderData, isSuccess: isOrderSuccess }] =
    useUpdateOrderMutation();

  const { data, refetch, isLoading } = useGetOrderByIdQuery(id);
  const {
    data: comments,
    refetch: reComments,
    isLoading: commentsLoading,
  } = useGetCommentsQuery({ id });
  const [commission, setCommission] = useState(data?.data?.commission);
  if (data) {
    var details = data?.data;
  }
  console.log(data, "datadata");
  const onSend = () => {
    addComment({ comment, order: id });
  };

  useEffect(() => {
    if (data?.data) {
      setPublished(data?.data?.isPublished);
      setCommission(data?.data?.commission);
    }
  }, [data]);

  useEffect(() => {
    if (invoiceSuccess || isDeleted) {
      refetch();
    }
  }, [invoiceSuccess, isDeleted]);

  useEffect(() => {
    if (commentSuccess) {
      setComment("");
      reComments();
    }
  }, [commentSuccess]);

  useEffect(() => {
    if (isSuccess) {
      let payload = {
        invoice: fileData.imageUrl,
        id,
      };

      addInvoice(payload);
    }
  }, [isSuccess]);

  const onDelete = () => {
    deleteInvoice(id);
  };

  useEffect(()=>{
    refetch()
  },[])
  const onChangeInvoice = (e) => {
    setInvoice(e.target.files[0]);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    upload(formData);
  };

  const handlePublishedChange = async (e) => {
    e.preventDefault();

    const isChecked = e.target.checked;
    setPublished(isChecked);
    await updateOrder({ _id: id, isPublished: isChecked });
    refetch();

    toast.success("Published status updated Successfully");
  };
  const handleCommissionChange = (e) => {
    const value = e.target.value;
    if (Number(value) >= 1 && Number(value) <= 100) {
      setCommission(value);
    }
  };

  const handleEditCommission = () => {
    setIsEditingCommission(true);
  };
  const handleSaveCommission = async (e) => {
    e.preventDefault();
    await updateOrder({ _id: id, commission });
    setIsEditingCommission(false);
    refetch();
    toast.success("Commission updated Successfully");
  };

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="headd1">
              <h2
                style={{
                  color: "#000",
                  padding: "36px 9px 20px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  fontSize: "30px",
                }}
              >
                {" "}
                <Link onClick={() => navigate(-1)} className="btn back">
                  <FaArrowLeft />
                </Link>
                Order Details
              </h2>
              {/* <img src={rectangle} /> */}
            </div>
            {user?.role === "admin" && (
              <div className="d-flex justify-content-end mb-3">
                <Button
                  text="Add Invoice"
                  onClick={() => invoiceRef?.current?.click()}
                />
                <input
                  ref={invoiceRef}
                  type="file"
                  hidden
                  onChange={onChangeInvoice}
                />
              </div>
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="col-md-12">
                <div className="texxtwrp">
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Order Date</h4>
                      <p>{format_date(details?.orderCreatedAt)}</p>
                    </div>
                    <div className="col-3">
                      <h4>Created On</h4>
                      <p>{format_date(details?.createdAt)}</p>
                    </div>
                    <div className="col-3">
                      <h4>Order Status</h4>
                      <p className="text-capitalize">{details?.status}</p>
                    </div>
                    <div className="col-3">
                      <h4>Order Paid Date</h4>
                      <p className="text-capitalize">
                        {format_date(details?.orderPaidOn) || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Salesman Name</h4>
                      <p>{details?.createdBy?.fullName}</p>
                    </div>
                    <div className="col-3">
                      <h4>Salesman Email</h4>
                      <p>{details?.createdBy?.email}</p>
                    </div>

                    {/* <div className="col-3">
                                                <h4>Salesman Comission</h4>
                                                <p>{details?.commission}%</p>
                                                </div> */}

                    <div className="col-3">
                      <h4>Salesman Commission</h4>

                      {user?.role === "admin" ? (
                        isEditingCommission ? (
                          <form onSubmit={handleSaveCommission}>
                            <div className="d-flex align-items-center flex-column">
                              <input
                                required
                                type="number"
                                value={commission}
                                onChange={handleCommissionChange}
                                className="form-control"
                                style={{ width: "70%" }}
                              />
                              <button
                                className="btn btn-success ml-2 mb-3"
                                type="submit"
                              >
                                <FaCheck /> Save
                              </button>
                            </div>
                          </form>
                        ) : (
                          <div className="d-flex align-items-center">
                            <p className="mb-0">{details?.commission}%</p>
                            {user?.role === "admin" && (
                              <FaEdit
                                className="mb-2 mx-2 "
                                onClick={handleEditCommission}
                              />
                            )}
                          </div>
                        )
                      ) : (
                        <p>{details?.commission}%</p>
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Organization No.</h4>
                      <p>{details?.customer?.organisationNumber}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Name</h4>
                      <p>{details?.customer?.fullName}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Email</h4>
                      <p>{details?.customer?.email || "-"}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Customer Type</h4>
                      <p>
                        {details?.customer?.newCustomer ? "A" : "B"} Customer
                      </p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Phone</h4>
                      <p>{details?.customer?.phone || "-"}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Ref. Name</h4>
                      <p>{details?.customer?.refPerson || "-"}</p>
                    </div>

                    <div className="col-3">
                      <h4>Mark as Published ?</h4>

                      {user?.role === "admin" ? (
                        <input
                          type="checkbox"
                          checked={published}
                          onChange={handlePublishedChange}
                        />
                      ) : (
                        <p>{published ? "Published" : "Not yet published"}</p>
                      )}
                    </div>
                  </div>
                  {/* <h4>Product Name</h4>
                                        <p>{details?.productName === "seo" ? "SEO" : "Google Ads"}</p>
                                        <h4>Number of {details?.productName === "seo" ? "Keywords" : "Clicks"}</h4>
                                        <p>{details?.number}</p>
                                        <h4>Product Price</h4>
                                        <p>{details?.orderValue} {CURRENCY}</p>
                                        <h4>Agreement Period (Monthly)</h4>
                                        <p>{details?.agreementPeriod}</p>
                                        <h4>Other Requests</h4>
                                        <p>{details?.otherRequests || "-"}</p> */}
                  {/* <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Salesman Name</h4>
                      <p>{details?.createdBy?.fullName}</p>
                    </div>
                    <div className="col-3">
                      <h4>Salesman Email</h4>
                      <p>{details?.createdBy?.email}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Organization No.</h4>
                      <p>{details?.customer?.organisationNumber}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Name</h4>
                      <p>{details?.customer?.fullName}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Email</h4>
                      <p>{details?.customer?.email || "-"}</p>
                    </div>
                  </div> */}

                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Product Name</h4>
                      <p>
                        {details?.productName === "seo"
                          ? "SEO"
                          : details?.productName === "website"
                          ? "Website"
                          : "Google Ads"}
                      </p>
                    </div>
                    <div className="col-3">
                      <h4>Order Price</h4>
                      <p>
                        {details?.orderValue} {CURRENCY}
                      </p>
                    </div>
                    {!details?.productName === "website" && (
                      <div className="col-3">
                        <h4>
                          Number of{" "}
                          {details?.productName === "seo"
                            ? "Keywords"
                            : "Clicks"}
                        </h4>

                        <p>{details?.number}</p>
                      </div>
                    )}
                    {details?.productName === "website" && (
                      <div className="col-3">
                        <h4>Old Link</h4>
                        <p>{details?.old_link || "-"}</p>
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Agreement Period (Monthly)</h4>
                      <p>{details?.agreementPeriod}</p>
                    </div>

                    {details?.productName === "website" && (
                      <div className="col-3">
                        <h4>Customer Social Media</h4>
                        <p>{details?.customer_social_media || "-"}</p>
                      </div>
                    )}

                    <div className="col-3">
                      <h4>Other Requests</h4>
                      <p>{details?.otherRequests || "-"}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    {details?.productName === "website" && (
                      <div className="col-3">
                        <h4>Customer Domain</h4>
                        <p>{details?.customer_domain || "-"}</p>
                      </div>
                    )}
                    {details?.productName === "website" && (
                      <div className="col-3">
                        <h4>Customer Service</h4>
                        <p>{details?.customer_services || "-"}</p>
                      </div>
                    )}
                    {details?.productName === "website" && (
                      <div className="col-3">
                        <h4>Customer Type</h4>
                        <p>{details?.customer_type || "-"}</p>
                      </div>
                    )}
                  </div>
                </div>
                {details?.pdf && (
                  <>
                    <h2
                      style={{
                        color: "#000",
                        padding: "36px 9px 20px",
                        fontWeight: 700,
                        lineHeight: "40px",
                        fontSize: "30px",
                      }}
                    >
                      Invoice
                    </h2>
                    <img
                      src={isPdf(details?.pdf) ? pdfImage : details?.pdf}
                      onClick={() =>
                        window.open(details?.pdf, "_blank", "noopener")
                      }
                      className="cursor-pointer"
                      style={{
                        height: "80px",
                        width: "70px",
                        objectFit: "contain",
                      }}
                    />
                    {user?.role === "admin" && (
                      <p
                        className="m-0 mt-1 cursor-pointer text-danger"
                        onClick={onDelete}
                      >
                        Delete Invoice
                      </p>
                    )}
                  </>
                )}
                 {details?.order_confirm_pdf && (
                  <>
                    <h2
                      style={{
                        color: "#000",
                        padding: "36px 9px 20px",
                        fontWeight: 700,
                        lineHeight: "40px",
                        fontSize: "30px",
                      }}
                    >
                      Order Confirm
                    </h2>
                    <img
                      src={isPdf(details?.order_confirm_pdf) ? pdfImage : details?.order_confirm_pdf}
                      onClick={() =>
                        window.open(details?.order_confirm_pdf, "_blank", "noopener")
                      }
                      className="cursor-pointer"
                      style={{
                        height: "80px",
                        width: "70px",
                        objectFit: "contain",
                      }}
                    />
                   
                  </>
                )}
                <>
                  <h2
                    style={{
                      color: "#000",
                      padding: "36px 9px 20px",
                      fontWeight: 700,
                      lineHeight: "40px",
                      fontSize: "30px",
                    }}
                  >
                    Comments
                  </h2>
                  {comments?.data?.length > 0 ? (
                    comments?.data?.map((item) => (
                      <div
                        key={item?._id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "auto",
                          alignItems:
                            user?._id === item?.from?._id
                              ? "flex-end"
                              : "flex-start",
                        }}
                      >
                        <p className="m-0 ml-3">
                          {user?._id === item?.from?._id
                            ? "You"
                            : item?.from?.fullName}
                        </p>
                        <div
                          className="p-3"
                          style={{
                            minWidth: "40%",
                            maxWidth: "70%",
                            minHeight: "70px",
                            backgroundColor: "lightgray",
                            borderRadius: "12px",
                            justifyContent: "space-around",
                          }}
                        >
                          <p className="text-black">{item?.comment}</p>
                          <p className="m-0 text-right">
                            {moment(item?.createdAt).format(
                              "DD-MM-YYYY hh:mm a"
                            )}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5 className="m-0 text-center">There are no comments</h5>
                  )}
                  <div className="my-3">
                    <InputField
                      placeholder="Enter message..."
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <Button text="Send" onClick={onSend} />
                  </div>
                </>
              </div>
            )}
          </div>
          {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;