import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import { useUploadFileMutation } from "../../../redux/apis/general";
import {
  useAddInvoiceMutation,
  useGetOrderByIdQuery,
} from "../../../redux/apis/order";
import { CURRENCY, format_date } from "../../../utils/helper";

const OrderDetails = () => {
  const { user } = useSelector((state) => state.userSlice);

  const { id } = useParams();

  const invoiceRef = useRef(null);
  const [invoice, setInvoice] = useState();

  const [upload, { data: fileData, isSuccess }] = useUploadFileMutation();
  const [addInvoice, { data: invoiceData, isSuccess: invoiceSuccess }] =
    useAddInvoiceMutation();

  const { data, refetch, isLoading } = useGetOrderByIdQuery(id);

  if (data) {
    var details = data?.data;
  }

  useEffect(() => {
    if (invoiceSuccess) {
      refetch();
    }
  }, [invoiceSuccess]);

  useEffect(() => {
    if (isSuccess) {
      let payload = {
        invoice: fileData.imageUrl,
        id,
      };

      addInvoice(payload);
    }
  }, [isSuccess]);

  const onChangeInvoice = (e) => {
    setInvoice(e.target.files[0]);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    upload(formData);
  };

  return (
    <div>
      <div className="main-wrapper">
        {/* <Header/>
        <SideBar/> */}
        <div className="page-wrapper">
          <div className="content">
            <div className="headd1">
              <h2
                style={{
                  color: "#000",
                  padding: "36px 9px 20px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  fontSize: "30px",
                }}
              >
                {" "}
                <Link to={`/${user?.role}/orders`} className="btn back">
                  <FaArrowLeft />
                </Link>
                Order Details
              </h2>
              {/* <img src={rectangle} /> */}
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="col-md-12">
                <div className="texxtwrp">
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Order Date</h4>
                      <p>{format_date(details?.orderCreatedAt)}</p>
                    </div>
                    <div className="col-3">
                      <h4>Created On</h4>
                      <p>{format_date(details?.createdAt)}</p>
                    </div>
                    <div className="col-3">
                      <h4>Order Status</h4>
                      <p className="text-capitalize">{details?.status}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Salesman Name</h4>
                      <p>{details?.createdBy?.fullName}</p>
                    </div>
                    <div className="col-3">
                      <h4>Salesman Email</h4>
                      <p>{details?.createdBy?.email}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Organization No.</h4>
                      <p>{details?.customer?.organisationNumber}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Name</h4>
                      <p>{details?.customer?.fullName}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Email</h4>
                      <p>{details?.customer?.email || "-"}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row row">
                    <div className="col-3">
                      <h4>Customer Type</h4>
                      <p>
                        {details?.customer?.newCustomer ? "A" : "B"} Customer
                      </p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Phone</h4>
                      <p>{details?.customer?.phone || "-"}</p>
                    </div>
                    <div className="col-3">
                      <h4>Customer Ref. Name</h4>
                      <p>{details?.customer?.refPerson || "-"}</p>
                    </div>
                  </div>
                  <h4>Product Name</h4>
                  <p>{details?.productName === "seo" ? "SEO" : "Google Ads"}</p>
                  <h4>
                    Number of{" "}
                    {details?.productName === "seo" ? "Keywords" : "Clicks"}
                  </h4>
                  <p>{details?.number}</p>
                  {/* <h4>Product Price</h4>
                                        <p>{details?.orderValue} {CURRENCY}</p> */}
                  <h4>Agreement Period (Monthly)</h4>
                  <p>{details?.agreementPeriod}</p>
                  <h4>Other Requests</h4>
                  <p>{details?.otherRequests || "-"}</p>
                </div>
                {(invoice || details?.pdf) && (
                  <>
                    <h2
                      style={{
                        color: "#000",
                        padding: "36px 9px 20px",
                        fontWeight: 700,
                        lineHeight: "40px",
                        fontSize: "30px",
                      }}
                    >
                      Invoice
                    </h2>
                    <img
                      src={
                        invoice ? URL.createObjectURL(invoice) : details?.pdf
                      }
                    />
                  </>
                )}
              </div>
            )}
          </div>
          {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;
