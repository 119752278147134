import React, { useEffect } from "react";
import { FaArrowLeft } from 'react-icons/fa6';
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputField from "./InputField";
import { useChangePasswordMutation } from "../redux/apis/user";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Button from "./Button";

const EditProfile2 = () => {

  const navigate = useNavigate()

  const { user } = useSelector(state => state.userSlice)
  const { control, formState: { errors }, handleSubmit, reset, getValues } = useForm()
  const [changePassword, { isSuccess, isLoading }] = useChangePasswordMutation()

  useEffect(() => {
    if (isSuccess) {
      toast.success("Password Changed Successfully")
      reset()
      navigate(`/${user?.role}/profile`)
    }
  }, [isSuccess])

  const onSubmit = data => {
    delete data.confirmPassword
    changePassword(data)
  }

  const validatePasswordMatch = (value) => {
    const newPassword = getValues("newPassword");
    if (value !== newPassword) {
      return "Passwords do not match";
    }
    return true;
  };

  return (
    <div>
      {/* <Header/>
        <SideBar/> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
              <div className="row">
                <div className="col-sm-4 col-3 main-heading-1">
                  <h2><Link to="/apprprofile" className="btn back"><FaArrowLeft /></Link>Change Password</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="currentPassword"
                      control={control}
                      rules={{
                        required: "Old Password is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Old Password"
                          placeholder="Enter Old Password"
                          type="password"
                          required
                          error={errors?.currentPassword?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="newPassword"
                      control={control}
                      rules={{
                        required: "New Password is required"
                      }}
                      render={({ field }) => (
                        <InputField
                          label="New Password"
                          placeholder="Enter New Password"
                          type="password"
                          required
                          error={errors?.newPassword?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-5 ofset-7">
                    <Controller
                      name="confirmPassword"
                      control={control}
                      rules={{
                        required: "Confirm Password is required",
                        validate: validatePasswordMatch,
                      }}
                      render={({ field }) => (
                        <InputField
                          label="Confirm Password"
                          placeholder="Enter Confirm Password"
                          type="password"
                          required
                          error={errors?.confirmPassword?.message}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12">
                    <Button text="Update" onClick={handleSubmit(onSubmit)} loading={isLoading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>

  );
}

export default EditProfile2;