import moment from "moment";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import popup from "../../../assests/popup.png";
import Table from "../../../components/Table";
import useTableControls from "../../../hooks/useTableControls";
import { useGetSalesmanAttendanceQuery } from "../../../redux/apis/attendance";
import { useUpdateUserMutation } from "../../../redux/apis/user";
import { format_date, getMonths } from "../../../utils/helper";
import { FaArrowLeft, FaArrowRight, FaCalendar } from "react-icons/fa";
import { useGetLeavesRequestsQuery } from "../../../redux/apis/leaves";
import Pagination from "../../../components/Table/Pagination";

const Leave = () => {

    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()
    const { data, refetch, isLoading, isFetching } = useGetLeavesRequestsQuery({ page, rowsPerPage, search })

    const onPageChange = page => setPage(page)

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-6 main-heading-1">
                                    <h2>Leaves</h2>
                                </div>
                                <div className="col-sm-6 col-9 m-b-20" >
                                    <a href="/salesman/leaves/apply" className="btn btn btn-primary btn-rounded float-right oder text-white px-2">Apply Leave</a>
                                    {/* <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} /> */}
                                </div>
                                {/* <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} /> */}
                            </div>
                            <Table
                                headings={['S. No', "From", "To", "Status"]}
                                data={data}
                                loading={isFetching}
                                length={data?.data?.length}
                            >

                                {
                                    data?.data?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{format_date(item?.from)}</td>
                                            <td>{format_date(item?.to)}</td>
                                            <td className={`text-capitalize ${item?.status === "rejected" ? "text-danger" : item?.status === "pending" ? "text-warning" : "text-success"}`} >{item?.status}</td>
                                            {/* <td onClick={() => navigate(`/admin/salary/detail/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">View</td> */}
                                        </tr>
                                    ))
                                }
                            </Table>
                            {
                                data?.data?.length > 0 &&
                                <Pagination
                                    length={data?.data?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </div>
                    </div>
                    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>

    );
}

export default Leave;