import { FaEye } from "react-icons/fa"

const InputField = ({ label, min, required, type = "text", placeholder = "", value, onChange, error, onBlur = () => { }, disabled = false }) => {

    return (
        <div className="md-form">
            <h6>{label} {required && <span className="text-danger">*</span>}</h6>
            <div>
                {
                    type === "textarea" ? (
                        <textarea
                            type={type}
                            placeholder={placeholder}
                            className="form-control"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={disabled}
                        />
                    ) : (
                        <input
                            min={min}
                            type={type}
                            placeholder={placeholder}
                            className="form-control"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={disabled}
                        />
                    )
                }
                {/* {type === "password" && <FaEye style={{ position: 'absolute', top: 0, right: 0 }} />} */}
            </div>
            {
                error && <p className="text-danger small mt-2 mb-0">{error}</p>
            }
        </div>
    )
}

export default InputField