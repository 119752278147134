import { createSlice } from '@reduxjs/toolkit'
import { AuthApiService } from '../apis/auth'
import { UserApiService } from '../apis/user'

const initialState = {
    loggedIn: false,
    token: null,
    user: null
}

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        signout: (state, action) => {
            state.loggedIn = false
            state.token = null
            state.user = null
            return state
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(AuthApiService.endpoints.login.matchFulfilled, (state, action) => {
            let { success, data, token } = action.payload
            if (success) {
                state.user = data
                state.loggedIn = true
                state.token = `Bearer ${token}`
            }
        })
        builder.addMatcher(UserApiService.endpoints.updateUser.matchFulfilled, (state, action) => {
            let { success, data } = action.payload
            if (success && data._id === state?.user?._id) {
                state.user = data
            }
        })
    }
})

export const { signout } = userSlice.actions
export default userSlice.reducer