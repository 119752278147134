import ReactPaginate from "react-paginate";

const Pagination = ({
  totalDocs = 0,
  length,
  page = 1,
  rowsPerPage = 10,
  onPageChange,
  currentPage=0,
}) => {
  const totalPages = Math.ceil(totalDocs / rowsPerPage);

  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  return (
    <div className="row m-t-20">
      <div className="col-sm-12 col-md-5">
        <div
          className="dataTables_info"
          id="DataTables_Table_0_info"
          role="status"
          aria-live="polite"
        >
          Showing {(page - 1) * rowsPerPage + 1 || "0"} to{" "}
          {length + (page - 1) * rowsPerPage || "0"} out of {totalDocs || "0"}{" "}
          Entries
        </div>
      </div>
      <div className="col-sm-12 col-md-7">
        <div
          className="dataTables_paginate paging_simple_numbers lsd"
          id="DataTables_Table_0_paginate"
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={(val) => onPageChange(val.selected + 1)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            previousLabel="Previous"
            initialPage={currentPage}
            renderOnZeroPageCount={null}
            pageClassName="paginate_button page-item page-link"
            containerClassName="pagination"
            nextClassName="paginate_button page-item next page-link"
            previousClassName="paginate_button page-item next page-link"
            activeClassName="active"
          />
        </div>
        {/* <ul className="pagination">
                    <li className={`paginate_button page-item previous ${page <= 1 && "disabled"}`} id="DataTables_Table_0_previous"><div onClick={onPrevious} className="page-link">Previous</div></li>
                    {
                        Array(totalPages)?.fill()?.map((item, index) => (
                            <li className={`paginate_button page-item ${index + 1 === page && "active"}`}><div onClick={() => onPageChange(index + 1)} className="page-link">{index + 1}</div></li>
                        ))
                    }
                    <li className={`paginate_button page-item next ${page >= totalPages && "disabled"}`} id="DataTables_Table_0_next"><div className="page-link" onClick={onNext}>Next</div></li>
                </ul> */}
      </div>
    </div>
  );
};

export default Pagination;
