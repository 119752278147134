import React, { useEffect } from "react";
// import popup from "../assests/popup.png"
import { FaSort } from 'react-icons/fa6';
import { FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

import { useSelector } from "react-redux";
import useTableControls from "../../../hooks/useTableControls";
import { useGetCustomersQuery, useUpdateCustomerMutation } from "../../../redux/apis/customer";
import Pagination from "../../../components/Table/Pagination";
import { format_date } from "../../../utils/helper";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";

const Customers = () => {

    const navigate = useNavigate()
    const { user } = useSelector(state => state.userSlice)
    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()

    const [updateCustomer, { isSuccess }] = useUpdateCustomerMutation()
    const { data, refetch, isLoading } = useGetCustomersQuery({ page, rowsPerPage, search })

    useEffect(() => {
        refetch()
    }, [isLoading, isSuccess])

    const onPageChange = page => setPage(page)

    const onStatusChange = (item) => {
        updateCustomer({ id: item?._id, status: item?.status === "active" ? "inactive" : 'active' })
    }

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2>Customer Management</h2>
                                </div>
                                <div className="col-sm-6 col-9 text-right m-b-20">
                                    <input type="search" className="search-1 search-4" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <Table
                                headings={['S. No', 'Username', 'Org. No', "Email", "Phone", "Type", "Reg. Date", "Status", "Action"]}
                                data={data}
                                loading={isLoading}
                                length={data?.data?.length}
                            >
                                {
                                    data?.data?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.organisationNumber}</td>
                                            <td>{item?.email || "-"}</td>
                                            <td>{item?.phone || "-"}</td>
                                            <td className={`${item?.newCustomer ? "text-success" : "text-primary"}`}>{item?.newCustomer ? "A" : "B"} Customer</td>
                                            <td>{format_date(item?.createdAt)}</td>
                                            <td className={item?.status == "active" ? "text-success" : "text-danger"} >{item?.status === "active" ? "Active" : "Inactive"}</td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <p className={`${item?.status === "active" ? "text-danger" : "text-success"} text-decoration-underline cursor-pointer my-0 mx-1`} onClick={() => onStatusChange(item)} >{item?.status === "active" ? "Mark as Inactive" : "Mark as Active"}</p>
                                                    <p className="text-primary text-decoration-underline cursor-pointer my-0 mx-1" onClick={() => navigate(`/admin/customers/edit/${item?._id}`)} >Edit</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </Table>
                            {
                                data?.data?.length > 0 &&
                                <Pagination
                                    length={data?.data?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </div>
                    </div>
                    {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div></div>

    );
}
export default Customers;