import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import popup from "../../../assests/popup.png";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import { useGetEmployeeSalaryQuery } from "../../../redux/apis/order";
import { useSelector } from "react-redux";
import { CURRENCY, getMonthYear, monthlyIntervalDate } from "../../../utils/helper";

const Salary = () => {

    const { user } = useSelector(state => state.userSlice)
    const navigate = useNavigate()

    const { from, to } = monthlyIntervalDate()

    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()
    const { data, refetch, isLoading } = useGetEmployeeSalaryQuery({ id: user?._id, from, to })

    console.log("Dadadadada", data?.data?.orders)

    useEffect(() => {
        refetch()
    }, [isLoading])

    const onPageChange = page => setPage(page)

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2>Salary Management</h2>
                                </div>
                                <div className="col-sm-6 col-9 m-b-20" >
                                    <input type="search" className="search-1" placeholder="Search Here..." onChange={e => setSearch(e.target.value)} />
                                </div>
                            </div>
                            <Table
                                headings={['S. No', 'Customer Name', "Customer Type", "Product Name", "Amount Earned", "Status"]}
                                data={data?.data?.orders}
                                loading={isLoading}
                                length={data?.data?.orders?.length}
                            >

                                {
                                    data?.data?.orders?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{item?.customer?.fullName}</td>
                                            <td className={`${item?.customer?.newCustomer ? "text-success" : "text-primary"}`}>{item?.customer?.newCustomer ? "A" : "B"} Customer</td>
                                            <td>{item?.productName}</td>
                                            <td>{item?.amountEarned} {CURRENCY}</td>
                                            <td className="text-capitalize text-success" >{item?.status}</td>
                                            {/* <td onClick={() => navigate(`/admin/salary/detail/${item?._id}`)} className="text-primary text-decoration-underline cursor-pointer">View</td> */}
                                        </tr>
                                    ))
                                }
                            </Table>
                            {
                                data?.data?.orders?.length > 0 &&
                                <Pagination
                                    length={data?.data?.orders?.length}
                                    totalDocs={data?.total}
                                    onPageChange={onPageChange}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }
                        </div>
                    </div>
                    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>

    );
}
export default Salary;