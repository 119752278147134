import React, { useEffect, useState } from "react";

// import popup from "../assests/popup.png"
import { AiFillFilter } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import useTableControls from "../../../hooks/useTableControls";
import { useGetOrdersQuery } from "../../../redux/apis/order";
import { CURRENCY, format_date } from "../../../utils/helper";
import Pagination from "../../../components/Table/Pagination";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "../../../components/Table";

const Orders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.userSlice);
  const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls();

  
    const [category, setCategory] = useState("all");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { data, isLoading, isFetching } = useGetOrdersQuery({
    page,
    rowsPerPage,
    createdBy: user?._id,
    search,
    from,
    to,
  });


  const onPageChange = (newPage) => {
    setPage(newPage);
  };


  let filteredData =
  data?.data?.filter((item) => {
    const deletedFilter = item.isDeleted === false;
    const categoryFilter =
      category === "all" ||
      item?.productName?.toLowerCase() === category?.toLowerCase();

    return deletedFilter && categoryFilter;
  }) || [];
  return (
    <div>
      {/* <Header/>
            <SideBar/> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content">
            <div className="color-bggg">
              
            <div className="row">
                <div className="col-sm-3 col-3 main-heading-1">
                  <h2>Orders</h2>
                </div>
                <div className="col-sm-9 col-5 m-b-20">
                  <div className="d-flex align-items-center gap-1 justify-content-center my-2">
                    <select
                      name=""
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      className="form-control"
                      id=""
                    >
                      <option value="all" selected>
                        All Products
                      </option>
                      <option value="google-adds">Google Ads</option>
                      <option value="seo">SEO</option>
                      <option value="website">Website</option>
                    </select>
                    <input
                      type="search"
                      name
                      className="form-control"
                      placeholder="Search Here...."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <input
                      type="date"
                      name
                      className="form-control"
                      placeholder="Search Here...."
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      max={to}
                    />
                    <input
                      type="date"
                      name
                      className="form-control"
                      placeholder="Search Here...."
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                      min={from}
                    />
                  </div>
               
                </div>
              </div>


              <Table
                headings={[
                  "S. No",
                  "Customer Name",
                  "Customer Email",
                  "Customer Type",
                  "Price",
                  "Category",
                  "Registration Date",
                  "Status",
                  "Action",
                ]}
                data={data}
                loading={isFetching}
                length={filteredData?.length}
              >
                {filteredData?.map((item, index) => (
                  <tr>
                    <td>{index + 1 + (page - 1) * rowsPerPage}</td>
                    <td>{item?.customer?.fullName}</td>
                    <td>{item?.customer?.email || "-"}</td>
                    <td
                      className={`${
                        item?.customer?.newCustomer
                          ? "text-success"
                          : "text-primary"
                      }`}
                    >
                      {item?.customer?.newCustomer ? "A" : "B"} Customer
                    </td>
                    <td>
                      {item?.orderValue} {CURRENCY}
                    </td>
                    
                    <td>
                      {item?.productName}
                    </td>
                    <td>{format_date(item?.orderCreatedAt)}</td>
                    <td
                      className={`text-capitalize ${
                        item?.status === "overdue"
                          ? "text-danger"
                          : item?.status === "paid"
                          ? "text-success"
                          : "text-black"
                      }`}
                    >
                      {item?.status}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <p
                          className="text-black text-decoration-underline cursor-pointer my-0 mx-1"
                          onClick={() =>
                            navigate(`/salesman/orders/edit/${item?._id}`, {
                              state: { order: item },
                            })
                          }
                        >
                          Edit
                        </p>
                        <p
                          className="text-primary text-decoration-underline cursor-pointer my-0 mx-1"
                          onClick={() =>
                            navigate(`/salesman/orders/detail/${item?._id}`)
                          }
                        >
                          View
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </Table>
              {data?.data?.length > 0 && (
                <Pagination
                  length={data?.data?.length}
                  totalDocs={data?.total}
                  onPageChange={onPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Orders;
