import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'customer/'

export const CustomerApiService = createApi({
    reducerPath: 'customerApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        findCustomer: builder.query({
            query: (number) => `searchCustomer?organisationNumber=${number}`
        }),
        addUser: builder.mutation({
            query: (payload) => {
                return {
                    url: `create`,
                    method: 'POST',
                    body: { ...payload, role: "salesman" }
                }
            }
        }),
        getCustomers: builder.query({
            query: (payload) => {
                if (payload) {
                    let { search, page, rowsPerPage, createdBy } = payload

                    return `all?${page && page !== '' ? `page=${page}` : ""}${rowsPerPage && rowsPerPage !== '' ? `&rowsPerPage=${rowsPerPage}` : ""}${search && search !== '' ? `&search=${search}` : ""}${createdBy && createdBy !== '' ? `&createdBy=${createdBy}` : ""}`
                } else {
                    return `all?`
                }
            },
            keepUnusedDataFor: 0
        }),
        getCustomerById: builder.query({
            query: (id) => `details/${id}`,
            keepUnusedDataFor: 0
        }),
        updateCustomer: builder.mutation({
            query: (payload) => {
                return {
                    url: `edit/${payload._id}`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        changePassword: builder.mutation({
            query: (payload) => {
                return {
                    url: `change-password`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
    })
})

export const { useGetCustomersQuery, useAddUserMutation, useUpdateCustomerMutation, useGetCustomerByIdQuery, useChangePasswordMutation, useLazyFindCustomerQuery } = CustomerApiService