import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useCreateOrderMutation } from "../../../redux/apis/order";
import { useCreateLeaveMutation } from "../../../redux/apis/leaves";
import { toast } from "react-toastify";
import moment from "moment";

const leavesTypes = [
    {
        label: 'Annual',
        value: 'annual'
    },
    {
        label: 'Sick',
        value: 'sick'
    }
]

const defaultValues = {
    from: null,
    to: null,
    type: leavesTypes[0].value,
    reason: ''
}

const ApplyLeave = () => {

    const navigate = useNavigate()

    const { control, handleSubmit, formState: { errors }, watch } = useForm({ defaultValues })
    const [applyLeave, { data: leaveData, isSuccess: leaveCreated, isLoading }] = useCreateLeaveMutation()

    let fromDate = watch('from')

    const onSubmit = data => {
        applyLeave(data)
    }

    useEffect(() => {
        if (leaveCreated) {
            navigate('/salesman/leaves')
            toast.success("Leave Applied Successfully")
        }
    }, [leaveCreated])

    return (
        <div>
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg" style={{ padding: '10px 10px 50px' }}>
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2 style={{ padding: '36px 9px 20px' }}>Apply For Leave</h2>
                                    {/* <div className="btn-ordr">
                    <a className="btnn-112" href="/myorder">My orders</a>
                  </div> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-sm-5 ofset-7">
                                        {
                                            console.log("Dhoom", new Date().toISOString().split('T')[0])
                                        }
                                        <Controller
                                            name="from"
                                            control={control}
                                            rules={{
                                                required: "From Date is required"
                                            }}
                                            render={({ field }) => (
                                                <InputField
                                                    label="From Date"
                                                    placeholder="Enter From Date"
                                                    type="date"
                                                    min={moment().format("YYYY-MM-DD")}
                                                    error={errors?.from?.message}
                                                    // required
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-5 ofset-7">
                                        <Controller
                                            name="to"
                                            control={control}
                                            rules={{
                                                required: "To Date is required"
                                            }}
                                            render={({ field }) => (
                                                <InputField
                                                    label="To Date"
                                                    placeholder="Enter To Date"
                                                    type="date"
                                                    min={moment(fromDate).format("YYYY-MM-DD")}
                                                    error={errors?.to?.message}
                                                    // required
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-5 ofset-7">
                                        <Controller
                                            name="type"
                                            control={control}
                                            rules={{
                                                required: "Type is required"
                                            }}
                                            render={({ field }) => (
                                                <div className="md-form">
                                                    <h6>Type <span className="text-danger">*</span></h6>
                                                    <select
                                                        className="form-control"
                                                        {...field}
                                                    >
                                                        {
                                                            leavesTypes.map(item => <option value={item.value}>{item.label}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-5 ofset-7">
                                        <Controller
                                            name="reason"
                                            control={control}
                                            rules={{
                                                required: "Reason is required"
                                            }}
                                            render={({ field }) => (
                                                <InputField
                                                    label="Reason"
                                                    placeholder="Enter Reason"
                                                    // required
                                                    error={errors?.reason?.message}
                                                    type="textarea"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Button text="Submit" onClick={handleSubmit(onSubmit)} loading={isLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplyLeave;    