import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../../utils/constants'

const baseUrl = BASE_URL + 'leaves/'

export const LeaveApiService = createApi({
    reducerPath: 'leaveApiService',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            let { token } = getState().userSlice
            headers.set('Authorization', token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getLeavesRequests: builder.query({
            query: (payload) => {
                if (payload) {
                    let { search, page, rowsPerPage, status } = payload

                    return `all?${page && page !== '' ? `page=${page}` : ""}${rowsPerPage && rowsPerPage !== '' ? `&rowsPerPage=${rowsPerPage}` : ""}${search && search !== '' ? `&search=${search}` : ""}${status && status !== '' ? `&status=${status}` : ""}`
                } else {
                    return `all?`
                }
            },
            keepUnusedDataFor: 0
        }),
        createLeave: builder.mutation({
            query: (payload) => {
                return {
                    url: `request`,
                    method: 'POST',
                    body: payload
                }
            }
        }),
        getLeaveById: builder.query({
            query: (id) => `details/${id}`
        }),
        changeLeaveStatus: builder.mutation({
            query: (payload) => {
                return {
                    url: `updateStatus/${payload.id}`,
                    method: 'POST',
                    body: payload
                }
            }
        })
    })
})

export const { useGetLeavesRequestsQuery, useCreateLeaveMutation, useGetLeaveByIdQuery, useChangeLeaveStatusMutation } = LeaveApiService