import { combineReducers, configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { AttendanceApiService } from "../apis/attendance";
import { AuthApiService } from "../apis/auth";
import { CustomerApiService } from "../apis/customer";
import { GeneralApiService } from "../apis/general";
import { OrderApiService } from "../apis/order";
import { UploadApiService } from "../apis/upload";
import { UserApiService } from "../apis/user";
import generalSlice from "../slice/generalSlice";
import userSlice from "../slice/userSlice";
import { LeaveApiService } from "../apis/leaves";
import { BudgetApiService } from "../apis/budget";
import { CommentApiService } from "../apis/comments";
import { CommissionApiService } from "../apis/commission";

const rtkQueryErrorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.warn('We got a rejected action!')
        toast.error(action?.payload?.data?.message)
    }

    return next(action)
}

const rootReducer = combineReducers({
    userSlice: userSlice,
    generalSlice: generalSlice,
    [AuthApiService.reducerPath]: AuthApiService.reducer,
    [UserApiService.reducerPath]: UserApiService.reducer,
    [UploadApiService.reducerPath]: UploadApiService.reducer,
    [CustomerApiService.reducerPath]: CustomerApiService.reducer,
    [OrderApiService.reducerPath]: OrderApiService.reducer,
    [GeneralApiService.reducerPath]: GeneralApiService.reducer,
    [AttendanceApiService.reducerPath]: AttendanceApiService.reducer,
    [LeaveApiService.reducerPath]: LeaveApiService.reducer,
    [BudgetApiService.reducerPath]: BudgetApiService.reducer,
    [CommentApiService.reducerPath]: CommentApiService.reducer,
    [CommissionApiService.reducerPath]: CommissionApiService.reducer,
})

const persistConfig = {
    key: 'root',
    storage: storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(AuthApiService.middleware)
            .concat(UserApiService.middleware)
            .concat(rtkQueryErrorLogger)
            .concat(UploadApiService.middleware)
            .concat(CustomerApiService.middleware)
            .concat(OrderApiService.middleware)
            .concat(GeneralApiService.middleware)
            .concat(AttendanceApiService.middleware)
            .concat(LeaveApiService.middleware)
            .concat(BudgetApiService.middleware)
            .concat(CommentApiService.middleware)
            .concat(CommissionApiService.middleware)
})

setupListeners(store.dispatch)

export const persistor = persistStore(store);