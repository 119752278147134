import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import useTableControls from "../../../hooks/useTableControls";
import { useGetOrdersQuery, useUpdateOrderMutation } from "../../../redux/apis/order";
import { format_date } from "../../../utils/helper";

const PaidOrders = () => {

    const navigate = useNavigate()

    const { settings } = useSelector(State => State.generalSlice)

    const [page, setPage, rowsPerPage, setRowsPerPage, search, setSearch] = useTableControls()
    const { data, isLoading, refetch } = useGetOrdersQuery({ page, rowsPerPage, search, status: "paid" })
    const [changeStatus, { isSuccess }] = useUpdateOrderMutation()

    useEffect(() => {
        if (isSuccess) {
            refetch()
            toast.success("Order Marked as Done")
        }
    }, [isSuccess])

    const onPageChange = page => setPage(page)

    const onMarkAsDone = (_id) => {
        changeStatus({ _id, completed: true })
    }

    return (
        <div>
            <div className="main-wrapper">
                {/* <Header/>
        <SideBar/> */}
                <div className="page-wrapper">
                    <div className="content">
                        <div className="color-bggg">
                            <div className="row">
                                <div className="col-sm-4 col-3 main-heading-1">
                                    <h2>Paid Orders</h2>
                                </div>
                                <div className="col-sm-6 col-9 m-b-20" >
                                    <input type="search" name className="search-1" placeholder="Search Here...." />
                                </div>
                            </div>
                            <Table
                                headings={['S. No', 'Salesman Name', 'Customer Name', 'Customer Type', "Registration Date", "Status", "Completed", "Action"]}
                                data={data}
                                loading={isLoading}
                                length={data?.data?.length}
                            >
                                {
                                    data?.data?.map((item, index) => (
                                        <tr>
                                            <td>{(index + 1) + ((page - 1) * rowsPerPage)}</td>
                                            <td>{item?.createdBy?.fullName}</td>
                                            <td>{item?.customer?.fullName}</td>
                                            <td className={`${item?.customer?.newCustomer ? "text-success" : "text-primary"}`}>{item?.customer?.newCustomer ? "A" : "B"} Customer</td>
                                            {/* <td>{item?.orderValue} {CURRENCY}</td> */}
                                            <td>{format_date(item?.createdAt)}</td>
                                            {/* <td>{format_date(item?.orderPaidOn)}</td> */}
                                            <td className={`text-capitalize text-success`}>
                                                {item?.status}
                                            </td>
                                            <td>{item?.completed ? "Yes" : "No"}</td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <p className="text-primary text-decoration-underline cursor-pointer my-0" onClick={() => navigate(`/employee/orders/detail/${item?._id}`)}>View</p>
                                                {!item?.completed && <p className="text-success text-decoration-underline cursor-pointer my-0 mx-2" onClick={() => onMarkAsDone(item._id)} >Mark as Done</p>}
                                            </div>
                                        </tr>
                                    ))
                                }
                            </Table>
                        </div>
                        <Pagination
                            length={data?.data?.length}
                            totalDocs={data?.total}
                            onPageChange={onPageChange}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    </div>
                    {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img src={popup} alt="" width={150} height={70} />
                                    <h3>Your password has been reset. Please login to continue.</h3>
                                    <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div></div>
    );
}
export default PaidOrders;