import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import media from "../assests/Media.svg";
import login from "../assests/login.png";
import { useForgetPasswordMutation } from "../redux/apis/auth";

const Password = () => {

  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [forgetPass, { isSuccess }] = useForgetPasswordMutation()

  useEffect(() => {
    if (isSuccess) {
      navigate('/verification', { state: { email } })
      toast.success("Code has been sent to your email")
    }
  }, [isSuccess])

  return (
    <div>
      <section className="does_work_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft bgg-cl">
              <div className="does_work_boxes">
                <div className="form-logo">
                  {/* <img src={media} /> */}
                </div>
                <div className="does_work_box1 ">
                  <h3>Password Recovery</h3>
                  <p>Enter email address to get a verification code</p>
                  <div className="contact_form_all">
                    <input type="hidden" name="_token" defaultValue="iCCu8L3Cd3S6umnTMIlEMZGMKGGRsgqQzASO63GR" />              <div className>
                      <div className="col-sm-12">
                        <div className="md-form">
                          <h6>Email Address</h6>
                          <input type="text" id="email" name="Email" placeholder="Enter Email Address" required="required" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="page_name" defaultValue="Postcard" />
                    <div className="col-md-12 login">
                      <button className="btn blue_btn" onClick={e => {
                        e.preventDefault()
                        forgetPass({ email })
                      }}>Continue</button>
                    </div>
                    <div className="col-sm-12">
                      <div className="col-md-12">
                        <div className="uuull">
                          <ul>
                            <li>Back to</li>
                            <li><a href="log-in" className="login-2">Login</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="frst-form-img">
                <img src={login} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div id="delete_patient" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={popup} alt="" width={150} height={70} />
                <h3>Your password has been reset. Please login to continue.</h3>
                <div className="m-t-20"> <a href="#" className="btn btn-white" data-dismiss="modal">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
}
export default Password;
